import {
  BUSINESS_SET_HOME_URLS,
  FEEDBACK_FORM_URLS,
  GA4_SCRIPT_SOURCES,
} from "./config.const";
import { EnvName } from "./config.type";

export const AUTH_PAGE_ENABLED: boolean =
  import.meta.env.VITE_AUTH_ENABLED === "True";

const VITE_ENV: EnvName = import.meta.env.VITE_ENV;

export const GA4_SCRIPT_SOURCE = GA4_SCRIPT_SOURCES[VITE_ENV ?? EnvName.LOCAL];

export const ENV: EnvName = VITE_ENV ?? EnvName.PRD;

export const BUSINESS_PAGE_HOME_URL = BUSINESS_SET_HOME_URLS[ENV];
export const FEEDBACK_FORM_URL = FEEDBACK_FORM_URLS[ENV];
export const LOCAL_LAW_30_URL =
  "https://www.nyc.gov/site/immigrants/about/language-and-disability-access.page";
export const NYC_AI_APPROACH = "https://www.nyc.gov/content/oti/pages/artificial-intelligence";
export const NYC_TERMS_URL = "https://www.nyc.gov/home/terms-of-use.page";
export const NYC_POLICY_URL = "https://www.nyc.gov/home/privacy-policy.page";
export const NYC_LANDING_PAGE = " https://www.nyc.gov/";
export const NY_BUSINESS_PAGE = "https://nyc-business.nyc.gov/nycbusiness/";
export default {
  AUTH_PAGE_ENABLED,
  businessPage: BUSINESS_PAGE_HOME_URL,
  chatbotDisclaimerPage:
    "https://nycgov-csg.cityapps.nycnet/nyc-resources/mycity-chatbot-disclaimer.page",
  ENV,
  FEEDBACK_FORM_URL,
  GA4_SCRIPT_SOURCE,
  googlePolicy: "https://policies.google.com/privacy",
  googleTerms: "https://policies.google.com/terms",
  LOCAL_LAW_30_URL,
  myCityPage: "https://mycity.nyc.gov/",
  NYC_TERMS_URL,
  NYC_POLICY_URL,
  NYC_LANDING_PAGE
};
