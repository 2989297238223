import { useContext, useEffect } from "react";
import cx from "classnames";

import { Hyperlink } from "~/components";
import { initTranslate } from "~/google-translate/translate";

import config, { FEEDBACK_FORM_URL } from "~/config/config";
import { globalContext } from "~/context";

import LogoWording from "~/assets/LogoWording.svg";
import styles from "./Header.module.css";

export const Header = (): JSX.Element => {
  const { shouldDisplayHeader } = useContext(globalContext);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    const remove = initTranslate();

    return remove;
  }, []);

  return (
    <header
      className={cx(styles.header, {
        [styles.headerCollapsed]: !shouldDisplayHeader,
      })}
      role={"banner"}
    >
      <div className={styles.headerWrapper}>
        <a className={styles.logoBar} href={config.myCityPage}>
          <img
            src={LogoWording}
            className={styles.nycLogo}
            alt="NYC My City Logo"
          />
          Official website of the City of New York
        </a>
        <div className={styles.navBar}>
          <span className={styles.navTitle}>
            Chatbot
            <span className={styles.superscriptTitle}>Beta</span>
          </span>
        </div>
        <div className={styles.languageBar}>
          <div id="google_translate_element"></div>
        </div>
      </div>
      <Hyperlink
        className={styles.feedbackFormButton}
        href={FEEDBACK_FORM_URL}
        isTargetBlank
      >
        FEEDBACK
      </Hyperlink>
    </header>
  );
};
